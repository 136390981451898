<template>
  <v-dialog
      v-model="isActive"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="fill-height"
  >

    <v-card tile class="fill-height">
      <v-system-bar
          color="transparent"
          window
      >
        <v-spacer />
        <v-icon
            id="film_details_close_icon"
            class="black--text"
            @click="isActive = false"
        >
          mdi-close
        </v-icon>
      </v-system-bar>
      <v-card-title>
        <v-text-field
            v-model="filter"
            prepend-inner-icon="mdi-magnify"
            @keypress="getTheMovieDBFilm(filter)"
        />
      </v-card-title>
<!--          v-if="!$store.state.isMobile"-->

      <v-card-text class="fill-height" >

        <div class="fill-height overflow-auto">
          <h1 v-if="theMovieDbFilm.length===0" class="align-center">non ci sono film che fanno riferimento alla
            ricerca</h1>
          <v-row class="fill-height">
            <v-col
                lg="4"
                md="12"
                sm="12"
                xl="4"
                v-for="(film,index) in theMovieDbFilm"
                :key="index"
            >
              <v-card
                  @click="$emit('prepopolateFilm',film)"
              >
                <v-img
                    class="white--text align-end"
                    height="400px"
                    :src="imageBaseUrl + film.backdrop_path"
                >
                  <v-card-title>{{ film.title }}</v-card-title>
                  <v-avatar
                      class="ma-3"
                      size="200"
                      height="300"
                      tile
                  >
                    <v-img :src="imageBaseUrl + film.poster_path"/>
                  </v-avatar>
                </v-img>
              </v-card>
            </v-col>
          </v-row>

        </div>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>
<script>
import {searchService} from "../services/searchServices";

export default {
  name: 'theMovieDbDialog',
  props: {
    value: {},
    prepopolateFilm: {},
  },
  data() {
    return {
      isActive: this.value,
      filter: "",
      theMovieDbFilm: [],
      //array che contiene la risposta al the moviadb
      imageBaseUrl: "https://image.tmdb.org/t/p/original/",
    }
  },
  methods: {
    getTheMovieDBFilm(value) {
      let queryString = [];
      let date = new Date();
      searchService
          .getMovie("it", value, undefined, false, date.getFullYear())
          .then(
              a =>
                  (this.theMovieDbFilm = a.data.results.filter(
                      a => a.poster_path && a.backdrop_path
                  ))
          );
    },
  },
  watch: {
    value() {
      this.isActive = this.value;
    },
    isActive() {
      this.$emit("input", this.isActive);
    },
  }
}
</script>
