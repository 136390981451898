<template>
  <div style="max-width: 100%">
    <v-row justify="center">
      <v-col cols="3">
        <v-img
            v-if="backgroundImage !== ''"
            :src="backgroundImage "
            class="white--text align-end"
            contain
            width="100%"

            @click="openImageSelectModel = true"
        >
          <v-avatar
              class="ma-3"
              size="20%"
              tile
          >
            <v-img
                contain
                :src="image"
            />
          </v-avatar>
        </v-img>
        <v-img
            v-else
            src="../../public/download (1).png"
            class="white--text align-center"
            contain
            @click="openImageSelectModel = true"
        />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="3" align="center">
        <v-btn
            color="accent"
            outlined
            @click="insertAnotherFilm"
        >
          Seleziona un altro film
        </v-btn>
      </v-col>
    </v-row>
    <!--<v-card  tile>
                    <v-img type="file" accept="image/*" @change="uploadImage($event)" :src="image"></v-img>
          </v-card>-->
    <change-image-model
        :id="id"
        v-model="openImageSelectModel"
        :poster="image"
        :back-drop="backgroundImage"
        @poster="setImage"
        @backDrop="setBackdropImage"
    />
    <v-row>
      <v-col>
        <v-text-field
            v-model="title"
            label="Titolo"
            clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            v-model="type"
            label="Genere"
            clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
            v-model="description"
            label="Description"
            clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
            v-model="cinelandia"
            label="selezionando questo flag il film verrà inserito in cinelandia"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
            border="top"
            colored-border
            type="info"
            elevation="2"
        >
          Per inserire le date , selezionare il primo giorno per cui il film è in programmazione successivamente selezionare l'ultimo giorno della programmazione del film il programma farà in modo di prendere l'intero range di date su cui sarà possibile selezionare degli orari.
        </v-alert>
        <v-date-picker
            v-model="date"
            range
            full-width
            elevation="2"
            class="mt-4"
            locale="it"
            :first-day-of-week="1"
            :allowed-dates="dateAllowed"
            @click:date="changeDate"
        />
        <date-hour-picker
            v-model="modalActive"
            :date="date"
            @hours="loadDataHours"
        />
      </v-col>
    </v-row>
    <v-row
        v-for="(d,index) in dates"
        :key="index"
    >
      <v-col
          class="mr-2"
          cols="3"
      >
        {{ getDay(new Date(d.day).getDay()) }} {{
          new Date(d.day).getDate()
        }}/{{ new Date(d.day).getMonth() + 1 }}/{{ new Date(d.day).getFullYear() }}
      </v-col>
      <v-col justify="start">
        <span
            v-for="(hour,hourIndex) in d.hours"
            :key="hourIndex"
        >
          <v-chip
              v-if="hour.selected"
              outlined
              color="primary"
              class="ml-5"
              close
              @click:close="hour.selected=false"
          >
            {{ hour.value }}
          </v-chip>
        </span>
      </v-col>
    </v-row>

    <v-row
        justify="center"
        class="pa-5"
    >
      <v-col>
        <v-btn
            color="error"
            depressed
            outlined
            block
            @click="insertAnotherFilm"
        >
          <v-icon class="pr-3">
            mdi-close-box
          </v-icon>
          CAMBIA FILM
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
            color="primary"
            depressed
            outlined
            block
            :disabled="saveFilmEnable"
            :loading="loadingAdd"
            @click="addFilm"
        >
          <v-icon class="pr-3">
            mdi-content-save
          </v-icon>
          INSERISCI FILM
        </v-btn>
      </v-col>
    </v-row>
    <theMovieDbDialog
        v-model="choseFilmDialog"
        @prepopolateFilm="prepopolateFilm"
    />
    <v-dialog
        v-model="openAddFilmDialog"
    >
      <v-card>
        <v-card-title>Inserimento effettuato con successo</v-card-title>
        <v-card-text>L'Inserimento del film {{ title }} è avvenuto con successo</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="primary"
              text
              @click="insertAnotherFilm"
          >
            Inserisci un altro film
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="$router.push({ path: 'modify' })"
          >
            Vai alla lista
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DateHourPicker from "../components/DateHourPicker.vue";
import db from "../firebaseConfiguration/firebaseInit";
import moment from "moment";

import {genreService} from "../services/genreServices";
import ChangeImageModel from "../components/ChangeImageModel";
import TheMovieDbDialog from "./TheMovieDbDialog";
import axios from "axios";

export default {
  name: "Create",
  components: {TheMovieDbDialog, ChangeImageModel, "date-hour-picker": DateHourPicker},
  data() {
    return {
      cinelandia: false,
      loadingAdd: false,
      image: "",
      backgroundImage: "",
      date: [],
      modalActive: false,
      id: "",
      title: "",
      type: "",
      lenght: "",
      description: "",
      startDate: "",
      endDate: "",
      dates: [],
      choseFilmDialog: false,
      imageBaseUrl: 'https://image.tmdb.org/3/t/p/original',

      openAddFilmDialog: false,
      openImageSelectModel: false,
      backDropImageFile: undefined,
      posterImageFile: undefined,
    };
  },
  computed: {
    saveFilmEnable() {
      if (
          this.image !== "" &&
          this.backgroundImage !== "" &&
          this.title !== "" &&
          this.startDate !== "" &&
          this.endDate !== ""
      ) {
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.choseFilmDialog = true;
  },
  methods: {
    setBackdropImage(value) {
      if (value.file) {
        this.backDropImageFile = value.file;
      }
      this.backgroundImage = value.filePath;
    },
    setImage(value) {
      if (value.file) {
        this.posterImageFile = value.file;
      }
      this.image = value.filePath;
    },
    changeDate() {
      if (this.date.length === 2) {
        this.startDate = this.date[0];
        this.endDate = this.date[1];
        this.modalActive = true;
      }
    },
    addFilm() {
      this.loadingAdd = true;
      let reference = db.storage().ref();
      let promiseArray = [
        this.posterImageFile ? this.posterImageFile : this.getImageFromUrl(this.image),
        this.backDropImageFile ? this.backDropImageFile : this.getImageFromUrl(this.backgroundImage)
      ];
      Promise.resolve(promiseArray)
          .then(result => {
            Promise.all(result).then(async r => {
              let urlImage
              let urlBackground
              if (typeof r[0] === 'string' || r[0] instanceof String) {
                urlImage = r[0];
              } else {
                let referenceImage = reference.child("image" + this.title + new Date() + "." + this.getImageExtension(r[0].type));
                await referenceImage.put(r[0]);
                urlImage = await referenceImage.getDownloadURL();
              }
              if (typeof r[1] === 'string' || r[1] instanceof String) {
                urlImage = r[1];
              } else {
                let referenceBackground = reference.child("background" + this.title + new Date() + "." + this.getImageExtension(r[1].type));
                await referenceBackground.put(r[1]);
                urlBackground = await referenceBackground.getDownloadURL();
              }
              let film = {
                image: urlImage,
                backgroundImage: urlBackground,
                title: this.title,
                type: this.type,
                description: this.description,
                startDate: this.startDate,
                endDate: this.endDate,
                dates: this.dates,
                cinelandia: this.cinelandia
              };
              await db.firestore()
                  .collection("film")
                  .add(film)
                  .then(()=> {
                    this.openAddFilmDialog = true
                      }).finally(()=>this.loadingAdd = false)
            });
          })
          .catch(err => console.error(err));
    },
    loadDataHours(value) {
      this.dates = [];
      // eslint-disable-next-line no-undef
      let sDate = moment(new Date(this.startDate));
      // eslint-disable-next-line no-undef
      let eDate = moment(new Date(this.endDate));

      do {
        let day = this.getDay(sDate.toDate().getDay());

        let obj = value.find(a => a.day === day);
        if (obj && obj.hours.filter(a => a.selected).length >0)
          this.dates.push({
            day: sDate.toDate(),
            hours: JSON.parse(JSON.stringify(obj.hours.filter(a => a.selected)))
          });
        sDate = sDate.add(1, "days");
        // console.log("start date",sDate.format("M/D/YYYY"));
        // console.log("endDate",eDate.format("M/D/YYYY"));
        // console.log("comparator",sDate <= eDate);
      } while (sDate <= eDate);
    },
    dateAllowed: val => new Date(val) >= new Date().setHours(0, 0, 0, 0),
    getDay(element) {
      switch (element) {
        case 0:
          return "Domenica";
        case 1:
          return "Lunedì";
        case 2:
          return "Martedì";
        case 3:
          return "Mercoledì";
        case 4:
          return "Giovedì";
        case 5:
          return "Venerdì";
        case 6:
          return "Sabato";

        default:
          break;
      }
    },

    prepopolateFilm(value) {
      this.id = value.id;
      this.title = value.title;
      this.image = this.imageBaseUrl + value.poster_path;
      this.backgroundImage = this.imageBaseUrl + value.backdrop_path;
      this.description = value.overview;
      this.type = "";
      genreService.getAllGenre().then(a =>
          a.data.genres.forEach(a => {
            if (value.genre_ids.includes(a.id)) {
              this.type += a.name + " ";
            }
          })
      );
      this.choseFilmDialog = false;
    },
    insertAnotherFilm() {
      this.title = "";
      this.image = "";
      this.backgroundImage = "";
      this.description = "";
      this.type = "";
      this.choseFilmDialog = true;
      this.date = [];
      this.dates = [];
      this.openAddFilmDialog = false;
    },
    async getBase64ImageFromUrl(imageUrl) {
      let res = await fetch(imageUrl);
      let blob = await res.blob();

      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.addEventListener(
            "load",
            function () {
              resolve(reader.result);
            },
            false
        );

        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      });
    },
    async getImageFromUrl(imageUrl) {
      try {
        const options = {
          cache: 'no-cache',
        };
        let res = await fetch(imageUrl, options);
        return await res.blob();
      } catch (e) {
        console.log(e);
        return imageUrl;
      }
    },
    getImageExtension(type) {
      switch (type) {
        case "image/apng":
          return "apng";
        case "image/avif":
          return "avif";
        case "image/gif":
          return "gif";
        case "image/jpeg":
          return "jpg";
        case "image/png":
          return "png";
      }
    }
  }
};
</script>

