import {Service} from "./service";


let rootPath = "/search";

export let searchService = {

    async  getMovie(language,query,page,include_adult,year) {
        const params = {language, query,page,include_adult,year};
        return await Service.get({
            url: `${rootPath}/movie`,
            queryParams: params
        })
    },


}