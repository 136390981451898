import {Service} from "./service";


let rootPath = "/genre/movie/list";

export let genreService = {

    async  getAllGenre() {
        return await Service.get({
            url: `${rootPath}`,
        })
    },


}