<template>
  <v-dialog v-model="isActive" fullscreen hide-overlay class="fill-height">
    <v-card class="fill-height ">
      <v-system-bar
          color="transparent"
          window
      >
        <v-spacer/>
        <v-icon
            id="film_details_close_icon"
            class="black--text"
            @click="isActive = false"
        >
          mdi-close
        </v-icon>
      </v-system-bar>
      <v-tabs grow style="height: 85%" class="overflow-y-auto">
        <v-tabs-slider></v-tabs-slider>
        <v-tab>Orari</v-tab>
        <v-tab @click="loadCards()">Preferiti</v-tab>
        <v-tab-item style="overflow-y: auto " class="fill-height">
          <v-card-text class="overflow-y-auto fill-height">
            <v-row justify="end" class="mr-5  fill-height">
              <div class="text-center mb-4">
                <v-btn
                    fab dark small color="primary" depressed
                    rounded
                    class="mr-1"
                    @click="info = !info"
                >
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </div>
              <v-alert
                  :value="info"
                  border="top"
                  style="width: 100%"
                  colored-border
                  type="info"
                  elevation="2"
                  transition="scale-transition"
              >
                <b>Orari.</b>
                <br>
                Per inserire gli orari basta selezionare gli orari nella lista sottostante ,
                <br>
                una volta selezionati questi diverranno verdi per confermare premere pulsante <i>INSERISCI ORARI</i>.
                <br>
                <b>Modifica degli orari.</b>
                <br>
                Se è necessario inserire dei nuovi orari premere il pulsante <v-icon color="primary">mdi-cog</v-icon> successivamente qua è possibile eliminare o aggiungere nuovi orari.
                Gli orari modificati verranno visualizzati sotto.
                <br>
                <b>Salvataggio Preferiti</b>
                <br>
                Premendo il pulsante  <v-icon color="pink">mdi-heart</v-icon> è possibile salvare la selezione corrente dandole un nome.
                <br>
                La selezione salvata sarà presente navigando nel tab <i>Preferiti</i> posto in alto .
                <br>
                Una volta entrati nella sezione <i>Preferiti</i> è possibile selezionare un set di dati salvato, cliccando accanto al nome sull'icona del cerchio <v-icon>mdi-checkbox-blank-circle-outline</v-icon>.
                <br>
                In questa maschera è possibile anche eliminare i preferiti che non volgiamo più tenere usando il pulsante con il centino <v-icon>mdi-delete-circle</v-icon> posto in fondo a destra di ogni sezione.
              </v-alert>
              <v-btn class="mr-1" @click="openModifyHours = !openModifyHours" fab dark small color="primary" depressed
                     rounded>
                <v-icon>mdi-cog</v-icon>
              </v-btn>
              <v-menu
                  v-model="preferitiModel"
                  bottom
                  right
                  transition="scale-transition"
                  origin="top left"
                  :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" fab dark small color="pink" depressed rounded>
                    <v-icon dark>mdi-heart</v-icon>
                  </v-btn>
                </template>
                <v-card width="300">
                  <v-card-text>
                    <v-text-field label="nome" v-model="cardName" class="ma-2"></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="addCards">Aggiungi ai preferiti</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-row>
            <v-row justify="center">
              <v-col v-for="(w,index) in weekDayComputed" :key="index" justify="center">
                <div style="text-align: center">{{ w.day }}</div>
                <div style="text-align: center ; margin: 5px" v-for="h in w.hours" :key="h.id">
                  <v-chip
                      :color="selected(h.selected)"
                      @click="h.selected = !h.selected"
                      :class="{ 'select': h.selected }"
                      small
                  >{{ h.value }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>

            <v-dialog v-model="openModifyHours" width="800">
              <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>Modifica Orari</v-card-title>
                <v-card-text>
                  <v-chip
                      close
                      class="ma-2"
                      v-for="h in hours"
                      :key="h.id"
                      @click:close="deleteChips(h.id)"
                  >{{ h.value }}
                  </v-chip>
                  <v-menu
                      transition="scale-transition"
                      offset-y
                      v-model="showAddTime"
                      :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip>
                        <v-icon v-on="on" cirlce>mdi-plus</v-icon>
                      </v-chip>
                    </template>
                    <v-time-picker format="24hr" v-model="time">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="showAddTime = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="saveChips(time)">OK</v-btn>
                    </v-time-picker>
                  </v-menu>
                </v-card-text>
                <v-card-actions></v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-tab-item>
        <v-tab-item style="overflow-y: auto " class="fill-height">
          <div style=" overflow-x: hidden;">
            <v-card v-for="card in cards" :key="card.id" class="ma-5">
              <v-card-title style="background-color: #600902 " class="white--text">
                <span>{{ card.name }}</span>
                <v-icon
                    class="ml-2"
                    color="white"
                    @click="selectCard(card) "
                >{{ card.id == selCard ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline' }}
                </v-icon>
                <v-spacer></v-spacer>
                <v-icon color="white" @click="deleteCard(card.id)">mdi-delete-circle</v-icon>
              </v-card-title>
              <v-row justify="center">
                <v-col v-for="(w,index) in card.days" :key="index" justify="center">
                  <div style="text-align: center">{{ w.day }}</div>
                  <div style="text-align: center ; margin: 5px" v-for="h in w.hours" :key="h.id">
                    <v-chip
                        :color="selected(h.selected)"
                        :class="{ 'select': h.selected }"
                        small
                    >{{ h.value }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="isActive = false ">annulla</v-btn>
        <v-btn color="primary" outlined @click="emitData() ">Inserisci orari</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import db from "../firebaseConfiguration/firebaseInit";

export default {
  name: "DateHourPicker",
  props: ["value", "date"],

  data() {
    return {
      info: false,
      isActive: this.value,
      dateRange: this.date,
      weekDay: [],
      hours: [],
      cards: [],
      selectedCard: [],
      selCard: "",
      openModifyHours: false,
      showAddTime: false,
      time: "",
      preferitiModel: false,
      cardName: "",
    };
  },
  computed: {
    weekDayComputed() {
      let weekSorter = {
        "luned": 1,
        "marted": 2,
        "mercoled": 3,
        "gioved": 4,
        "venerd": 5,
        "sabato": 6,
        "domenica": 7
      }
      let w = this.weekDay;

      w.sort(function sortByDay(a, b) {
        let day1 = a.day.toLowerCase();
        let day2 = b.day.toLowerCase();
        return weekSorter[day1.replace("ì", "")] - weekSorter[day2.replace("ì", "")];
      });
      return w;
    }
  },
  methods: {
    saveChips(hour) {
      db.firestore().collection("hour")
          .add({
            hour: hour,
          })
          .then(_ => {
            this.loadHours().then(_ => {
              this.showAddTime = false;
              this.loadDateRange();
            });
          });
    },
    closeChip(day, idHours) {
      for (let i = 0; i < this.weekDay.length; i++) {
        if (this.weekDay[i].day === day) {
          for (let o = 0; o < this.weekDay[i].hours; o++) {
            if (this.weekDay[i].hours[o].id === idHours) {
              this.weekDay[i].hours.splice(o, 1);
            }
          }
        }
      }
    },
    loadCards() {
      this.cards = [];
      db.firestore().collection("cards")
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(element => {
              this.cards.push({
                days: element.data().days,
                id: element.id,
                name: element.data().name
              });
            });
          });
    },
    loadHours() {
      this.hours = [];
      return db.firestore().collection("hour")
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(element => {
              this.hours.push({
                id: element.id,
                value: element.data().hour,
                selected: false
              });
            });
          });
    },
    deleteCard(id) {
      db.firestore().collection("cards")
          .doc(id)
          .delete()
          .then(a => {
            console.log("elemento cancellato ");
            this.loadCards();
          });
    },
    deleteChips(id) {
      db.firestore().collection("hour")
          .doc(id)
          .delete()
          .then(a => {
            console.log("elemento cancellato ");
            this.loadHours().then(_ => {
              this.loadDateRange();
            });
          });
    },
    addCards() {
      db.firestore().collection("cards")
          .add({id: 2, name: this.cardName, days: this.weekDay})
          .then();
      this.preferitiModel = false;
    },
    selected(d) {

      return d ? "green" : "reed";
    },
    getDay(element) {
      switch (element) {
        case 0:
          return "Domenica";
        case 1:
          return "Lunedì";
        case 2:
          return "Martedì";
        case 3:
          return "Mercoledì";
        case 4:
          return "Giovedì";
        case 5:
          return "Venerdì";
        case 6:
          return "Sabato";

        default:
          break;
      }
    },
    loadDateRange() {
      this.weekDay = [];

      if (this.dateRange.length === 2) {
        this.dates = [];
        let startDate = new Date(this.dateRange[0]);
        let endDate = new Date(this.dateRange[1]);
        let diff = endDate.getTime() - startDate.getTime();
        let dayBetween = diff / 86400000;
        for (let i = 0; i < dayBetween + 1; i++) {
          this.dates.push(new Date(startDate.getTime() + 86400000 * i));
        }
        let days = this.dates
            .map(a => a.getDay())
            .sort()
            .filter(function (value, index, self) {
              return self.indexOf(value) === index;
            });
        days.forEach(element => {
          this.weekDay.push({
            day: this.getDay(element),
            hours: JSON.parse(JSON.stringify(this.hours))
          });
        });
      }
    },
    emitData() {
      this.dialog = false;
      this.$emit("hours", this.selectedCard.length > 0 ? this.selectedCard : this.weekDayComputed)
      this.isActive = false;
    },
    selectCard(value) {
      this.selectedCard = value.days;
      this.selCard = value.id;


    }
  },
  watch: {
    dateRange() {
      this.loadDateRange()
    },
    value() {
      this.isActive = this.value;
    },
    date() {
      this.dateRange = this.date;
    },
    isActive() {
      if(this.isActive){
        this.selectedCard = []
      }
      this.$emit("input", this.isActive);
    }
  },
  mounted() {
    db.firestore().collection("hour")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(element => {
            this.hours.push({
              id: element.id,
              value: element.data().hour,
              selected: false
            });
          });
        });
  }
};
</script>

<style>
.v-dialog {
  overflow-y: hidden;
}
</style>
