import axios from "axios";
import {Utility} from "../util/Utility";
import {Loading} from "../objects/Loading";


const apiKey = "?api_key=d744d777023e1cf54e3f9a6fc83d7123";

function errorResponseHandler(error) {
    if (error && error.response && (error.response.status == 417 || error.response.status == 401)) {
        return Promise.reject(error);
    }
    Loading.stop();
    //Alert.showRequestErrorMessage(error);
    throw new Error(error);
}

export let Service = {
    getRequestString: function (url, pathParams) {
        if (!url) {
            return;
        }

        if (!pathParams) {
            return url;
        }

        for (const urlParam in pathParams) {
            url = url.replace(
                "{" + urlParam + "}",
                pathParams[urlParam] ? pathParams[urlParam] : ""
            );
        }
        return url;
    },
    get: function (options) {
        if (!options.url)
            return null;
        options = Utility.extendsObject({}, Service.defaults, options);

        let request = options.url;

        if (options.pathParams)
            request = this.getRequestString(options.url, options.pathParams);

        let queryParams = new URLSearchParams();
        queryParams.append('api_key','d744d777023e1cf54e3f9a6fc83d7123')
        if (options.queryParams)
            if (options.queryParams instanceof Array)
                for (let i = 0; i < options.queryParams.length; i++) {
                    addElementFromObject(options.queryParams[i], queryParams);
                }
            else
                addElementFromObject(options.queryParams, queryParams);

        return axios(
            {
                headers: {
                    "Content-Type": options.contentType,
                },
                method: 'get',
                url: request,
                baseURL: options.baseURL,
                timeout: options.timeout,
                params: queryParams,
            })
            .catch(errorResponseHandler)
            .then(response => Promise.resolve(response));
    },
    delete: function (options) {
        options = Utility.extendsObject({}, Service.defaults, options);
        if (!options.url)
            return;

        let urlDelete = options.baseURL + options.url;
        return axios['delete'](urlDelete, {data: options.data ? options.data : {}})
            .catch(errorResponseHandler)
            .then(response => Promise.resolve(response));
        ;
    },

    putPost: function (type, options) {
        options = Utility.extendsObject({}, Service.defaults, options);

        if (!options.url)
            return;

        return axios[type](options.url, options.data ? options.data : {},
            {
                headers: {
                    "Content-Type": options.contentType
                },
                baseURL: options.baseURL,
                timeout: options.timeout,
            })
            .catch(errorResponseHandler)
            .then(response => Promise.resolve(response));
    },

    put: function (options) {
        return this.putPost("put", options)
    },
    post: function (options) {
        return this.putPost("post", options)
    }
}


function addElementFromObject(object, paramsObject) {
    for (const key in object) {
        let value = object[key];
        if (value instanceof Array)
            addElementFromArray(key, value, paramsObject);
        else if (value !== null && value !== undefined && value !== "")
            paramsObject.append(key, value);
    }
}

function addElementFromArray(key, values, paramsObject) {
    for (let i = 0; i < values.length; i++) {
        const value = values[i];
        if (value !== null && value !== undefined && value !== "")
            paramsObject.append(key, value);
    }
}
// http://localhost:8080/admin/api.themoviedb.org/3/search/movie?api_key=d744d777023e1cf54e3f9a6fc83d7123&language=it&include_adult=false&year=2019 404 (Not Found)
Service.defaults = {
    url: undefined,
    contentType: "application/json",
    timeout: 30000,
    baseService: undefined,
    baseURI: "api.themoviedb.org/3",
    pathParams: undefined,
    queryParams: undefined,
    data: undefined,
    callbacks: {
        onSuccess: undefined,
        onError: undefined,
        finally: undefined,
        manageLoading: false,
        loadingItem: undefined
    },
    onSuccessCallback: undefined,
    onErrorCallback: undefined,
    finallyCallback: undefined,

    get baseURL() {
        if (this.test)
        // servizi rest creati su swagger
            return "https://virtserver.swaggerhub.com/netstudio/truly-rest/1.0.0";
        else
            return "https://api.themoviedb.org/3";
    }
}
