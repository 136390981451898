<template>
  <v-dialog
    v-model="isActive"
    fullscreen
    class="overflow-y-auto overflow-x-hidden fill-height"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      class="overflow-x-hidden overflow-x-auto fill-height"
    >
      <v-system-bar
        color="transparent"
        window
      >
        <v-spacer />
        <v-icon
          id="film_details_close_icon"
          class="black--text"
          @click="isActive = false"
        >
          mdi-close
        </v-icon>
      </v-system-bar>
<!--      card -->
      <v-card-text style="height: 85%">
        <v-tabs
          fixed-tabs
        >
          <v-tab>Sfondi</v-tab>
          <v-tab>Locandina</v-tab>
          <v-tab-item class="fill-height">
            <v-row
              align="center"
              justify="center"
            >
              <v-navigation-drawer
                v-model="start"
                app
                temporary
              >
                <v-list
                  tile
                >
                  <v-list-item
                    v-for="(img,index) in backdrops"
                    :key="index"
                    link
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ img.height }}X{{ img.width }}</v-list-item-title>
                      <v-img
                        class="image"
                        :src="imageBaseUrl+img.file_path"
                        @click="selectedBackDrop=img"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-navigation-drawer>

              <v-col 
                md="4"
              >
                <div class="pa-5" v-if="selectedBackDrop.localFilePath || selectedBackDrop.file_path !== ''">
                  <v-img
                    class="image"
                    width="500"
                    height="200"
                    contain
                    :src="selectedBackDrop.localFilePath ? selectedBackDrop.localFilePath: imageBaseUrl+selectedBackDrop.file_path"
                    @click="backdrops.length>0 ? start=true :''"
                  />
                  {{ selectedBackDrop.height }}{{ selectedBackDrop.height ? 'X':'' }}{{ selectedBackDrop.width }}
                </div>
                <div class="pa-5" v-else>
                  <v-img
                      class="image"
                      width="500"
                      height="200"
                      contain
                      src="../../public/download (1).png"
                  />
                </div>
                <v-file-input
                  v-model="inputBackDrop"
                  label="carica immagine"
                  outlined
                  class="ma-4"
                  prepend-icon=""
                  prepend-inner-icon="mdi-camera"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="fill-height">
            <v-row
              align="center"
              justify="center"
            >
              <v-navigation-drawer
                  v-model="startPoster"
                  app
                  temporary
              >
                <v-list
                    tile
                >
                  <v-list-item
                      v-for="(img,index) in posters"
                      :key="index"
                      link
                  >
                    <v-list-item-content>
                      <v-list-item-title> {{ img.height }}X{{ img.width }}</v-list-item-title>
                      <v-img
                          class="image"
                          :src="imageBaseUrl+img.file_path"
                          @click="selectedPoster=img"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-navigation-drawer>

              <v-col
                md="4"
                sm="6"
              >
                <div class="pa-5" v-if="selectedPoster.localFilePath || selectedPoster.file_path !== ''">
                  <v-img
                    height="200"
                    contain
                    class="image"
                    @click="posters.length>0 ? startPoster = true : ''"
                    :src="selectedPoster.localFilePath ? selectedPoster.localFilePath :imageBaseUrl+selectedPoster.file_path "
                  />
                  {{ selectedPoster.height }}{{ selectedPoster.height ? 'X':'' }}{{ selectedPoster.width }}
                </div>
                <div class="pa-5" v-else>
                  <v-img
                      class="image"
                      width="500"
                      height="200"
                      contain
                      src="../../public/download (1).png"
                  />
                </div>
                <v-file-input
                  v-model="inputPoster"
                  label="carica immagine"
                  outlined
                  class="ma-4"
                  prepend-icon=""
                  prepend-inner-icon="mdi-camera"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="error"
          @click="isActive = false"
        >
          annulla
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="addImage"
        >
          Inserisci Immagini
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import {filmService} from "../services/filmServices"

    export default {
        name: "ChangeImageModel",
        props: ["value", "id", "poster", "backDrop"],

        data() {
            return {
                isActive: this.value,
                idFilm: this.id,
                backdrops: [],
                posters: [],
                imageBaseUrl: "https://image.tmdb.org/t/p/w500/",
                selectedBackDrop: {
                  file_path: this.backDrop,
                  localFilePath:undefined,
                  file:undefined
                },
                selectedPoster: {
                  file_path: this.poster,
                  localFilePath:undefined,
                  file:undefined,
                },
              inputBackDrop:undefined,
              inputPoster:undefined,
              start:false,
              startPoster:false
            };
        },
        watch: {

            value() {
                this.isActive = this.value;

            },
            isActive() {
                this.$emit("input", this.isActive);
            },
            id() {
                this.idFilm = this.id
            },
            idFilm() {
                filmService.getImagesFilm(this.idFilm, "").then(a => {
                    this.backdrops = a.data.backdrops;
                    this.posters = a.data.posters.sort(function (a, b) {
                        if ("it".includes(a.iso_639_1) || "en".includes(a.iso_639_1)) {
                            return 1;
                        }
                        return -1;
                    });
                });
            },
            poster() {
                this.selectedPoster.file_path =  this.poster.replace(this.imageBaseUrl, "");
              this.selectedBackDrop.localFilePath = undefined;
              this.selectedBackDrop.file = undefined;
            },
            backDrop() {
                this.selectedBackDrop.file_path = this.backDrop.replace(this.imageBaseUrl, "");
                this.selectedBackDrop.localFilePath = undefined;
                this.selectedBackDrop.file = undefined;
            },
          inputBackDrop(){
              this.selectedBackDrop.file = this.inputBackDrop;
              let objectURL = URL.createObjectURL(this.inputBackDrop);
              if (objectURL)
            this.selectedBackDrop.localFilePath = objectURL;
          },
          inputPoster(){
            this.selectedPoster.file = this.inputPoster;
            let objectURL = URL.createObjectURL(this.inputPoster);
            if (objectURL)
              this.selectedPoster.localFilePath = objectURL;
          }

        },
        mounted() {
          this.idFilm = this.id
        },
        methods: {
            addImage() {
                if (this.selectedBackDrop !== "") {
                    let backDropNewImage = {
                      file : this.selectedBackDrop.file,
                      filePath: this.selectedBackDrop.localFilePath ? this.selectedBackDrop.localFilePath : this.imageBaseUrl +  this.selectedBackDrop.file_path,
                    }
                    this.$emit("backDrop",backDropNewImage);
                }
                if (this.selectedPoster !== "") {
                  let posterNewImage = {
                    file : this.selectedPoster.file,
                    filePath: this.selectedPoster.localFilePath ? this.selectedPoster.localFilePath : this.imageBaseUrl + this.selectedPoster.file_path,
                  }
                  this.$emit("poster", posterNewImage);
                }
                this.isActive = false;
            }
        },
    };
</script>

<style>
    .image:hover {
        cursor: pointer;
    }
</style>
