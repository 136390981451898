import {Service} from "./service";


let rootPath = "/movie";

export let filmService = {

    async getUpcomingFilm(filters) {
        return await Service.get({
            url: `${rootPath}/upcoming`,
            queryParams: filters
        })
    },

    async getImagesFilm(idFilm,language) {
        let filters = {language};
        return await Service.get({
            url: `${rootPath}/${idFilm}/images`,
            queryParams: filters
        })
    },

}