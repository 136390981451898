export const Utility =
    {
        loadDataFromResponse: function(response, defaultValue) {
            return response && response.data ? response.data : defaultValue;
        },
        extendsObject: function (target, ...sources) {
            sources.forEach(source => {
                Object.defineProperties(target, Object.keys(source).reduce((descriptors, key) => {
                    descriptors[key] = Object.getOwnPropertyDescriptor(source, key);
                    return descriptors;
                }, {}));
            });
            return target;
        },

        // ─────────────────────────────────────────────────────────────────────────────────────────────────────────────
        // Pagination
        // ─────────────────────────────────────────────────────────────────────────────────────────────────────────────
        extendPagination(pagination) {
            const {page, rowsPerPage} = pagination;
            let offset = (page - 1) * rowsPerPage;
            if (offset < 0)
                offset = 0;

            let orderType = pagination.sortBy;
            let orderDirection = pagination.descending ? -1 : 1;

            return Object.assign({}, pagination, {offset, orderType, orderDirection});
        },
        getPages(pagination) {
            if (pagination.rowsPerPage == null || pagination.totalItems == null)
                return 0;

            return Math.ceil(pagination.totalItems / pagination.rowsPerPage)
        }
    }
