import Vue from "vue";

export const Loading = new Vue({
	methods: {
		start(spinner) {
			this.$emit("show", spinner);

		},
		stop() {
			this.$emit("hide");
		},
	}
});
